<template>
    <div class="list-container">
        <HeaderBar type="2" />

        <div class="list-banner-container"></div>
        <div class="rect-view section-ctx">
            <div class="ctx-1 wow fadeInUp"></div>

            <div class="list-items-container">
                <router-link
                    target="_blank"
                    class="list-items wow fadeInUp"
                    :to="`/details?id=${items.id}`"
                    v-for="(items, index) in list"
                    :key="index"
                >
                    <template v-if="index % 2 == 0">
                        <div class="list-items-half list-items-half-bg">
                            <div class="news-container">
                                <div class="news-time">新闻稿 {{items.update_time}}</div>
                                <div class="news-title ell-1">
                                    {{ items.title }}
                                </div>
                                <div class="news-desc ell-3">
                                    {{ items.summary }}
                                </div>
                                <div class="guide-stlye-1"></div>
                            </div>
                        </div>
                        <div class="list-items-half list-items-half-pic">
                            <img
                                class="pic-style"
                                :src="items.image"
                                alt=""
                                srcset=""
                            />
                        </div>
                    </template>
                    <template v-else>
                        <div class="list-items-half list-items-half-pic">
                            <img
                                class="pic-style"
                                :src="items.image"
                                alt=""
                                srcset=""
                            />
                        </div>
                        <div class="list-items-half list-items-half-bg">
                            <div class="news-container">
                                <div class="news-time">新闻稿 {{items.update_time}}</div>
                                <div class="news-title ell-1">
                                    {{ items.title }}
                                </div>
                                <div class="news-desc ell-3">
                                    {{ items.summary }}
                                </div>
                                <div class="guide-stlye-1"></div>
                            </div>
                        </div>
                    </template>
                </router-link>
            </div>
        </div>

        <Footer />
    </div>
</template>

<script>
import Vue from "vue";
import { Toast } from "vant";

Vue.use(Toast);
import HeaderBar from "@/components/HeaderBar.vue";
import Footer from "@/components/Footer.vue";
export default {
    components: {
        HeaderBar,
        Footer,
    },
    data() {
        return {
            list: []
        };
    },
    created() {

        _hmt.push(['_trackPageview', '/pc-文章列表']);

        Toast.loading({
            forbidClick: true,
        });

        $.get("https://www.hzyupin.com/index/News/getlist", (res) => {
            this.list = res.data.data;
            // console.log(this.list);
        });

        Toast.clear();

        this.$nextTick(() => {
            new WOW().init({
                offset: 10,
            });
        });
    },
};
</script>

<style lang="scss">
.list-container {
    max-width: 1920px;
    margin: 0 auto;
    background-image: url(../../public/images/31.jpg);
    .list-items-container {
        margin-top: 65px;
    }
    .guide-stlye-1 {
        margin-top: 30px;
        margin-left: 517px;
        width: 43px;
        height: 10px;
        transition: all 0.5s;
        background-size: 100%;
        background-repeat: no-repeat;
        background-image: url(../../public/images/24.png);
    }

    .news-desc {
        position: relative;
        margin-top: 40px;
        font-size: 16px;
        color: #0f0101;
        line-height: 34px;
    }

    .news-title {
        margin-top: 16px;
        padding-bottom: 20px;
        font-size: 24px;
        font-weight: bold;
        color: #0f0101;
        border-bottom: 1px solid #a9b9ba;
    }

    .news-time {
        margin-top: 85px;
        font-size: 14px;
        color: #0f0101;
        opacity: 0.7;
        font-weight: bold;
    }

    .pic-style {
        display: block;
        height: 100%;
        transition: all 0.5s;
    }
    .list-items-half-bg {
        position: relative;
        padding: 0 40px;
        overflow: hidden;
        background-color: white;
    }
    .list-items-half-pic {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        overflow: hidden;
    }
    .ctx-1 {
        width: 1200px;
        height: 125px;
        margin: 52px auto 0;
        background-size: 100%;
        background-repeat: no-repeat;
        background-image: url(../../public/images/23.png);
    }
    .list-items {
        display: flex;
        margin-bottom: 60px;
        &:hover {
            .guide-stlye-1 {
                transform: translate3d(6px, 0, 0);
            }
            .pic-style {
                transform: scale(1.05);
            }
        }
    }
    .list-items-half {
        width: 50%;
        height: 412px;
    }
}
.list-banner-container {
    width: 100%;
    height: 1003px;
    background-size: 1920px 1005px;
    background-repeat: no-repeat;
    background-position: center top;
    background-image: url(../../public/images/list_bg.png);
}
</style>